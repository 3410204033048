import React from 'react';
import './Connectivity.css'; // For styling
import nodeData from './Nodes.json'; // Import the JSON file
import { Helmet } from 'react-helmet';

const Connectivity = () => {
  const nodes = nodeData.nodes; // Access the node data from the imported JSON

  const handleRedirect = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="nodes-container">
      <Helmet>
        <meta name="description" content="Connectivity Overview for Bear Cub Network. Learn more about our connections in differnet areas." />
        <meta property="og:title" content="Connectivity Overview - Bear Cub Network" />
        <meta property="og:description" content="Connectivity Overview for Bear Cub Network. Learn more about our connections in differnet areas." />
        <meta property="og:url" content="https://bearcub.io/connectivity" />
      </Helmet>
      <header>
        <h1>Connectivity Overview</h1>
      </header>
      <div className="nodes-grid">
        {nodes.map((node, index) => (
          <div key={index} className="node-card">
            <div className="node-content">
              <button 
                className="redirect-button"
                onClick={() => handleRedirect('https://bearcub.io/connectivity')}
              >
                Looking Glass
              </button>
              <h2 className="node-title">{node.hostname}</h2>
              <p className="node-info">Location: <span className="node-detail">{node.location}</span></p>
              <p className="node-info">Connectivity: <span className="node-detail">{node.connectivity}</span></p>
            </div>
          </div>
        ))}
      </div>
      <div className="network-bg"></div>
    </div>
  );
};

export default Connectivity;
