import React, { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    const canvas = document.getElementById('network-bg');
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = 200;

    const particles = [];
    const particleCount = 50;

    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * 2 + 1;
        this.speedX = Math.random() * 3 - 1.5;
        this.speedY = Math.random() * 3 - 1.5;
      }
      update() {
        this.x += this.speedX;
        this.y += this.speedY;
        if (this.x > canvas.width) this.x = 0;
        else if (this.x < 0) this.x = canvas.width;
        if (this.y > canvas.height) this.y = 0;
        else if (this.y < 0) this.y = canvas.height;
      }
      draw() {
        ctx.fillStyle = 'rgba(241, 208, 209, 0.5)';
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    function init() {
      for (let i = 0; i < particleCount; i++) {
        particles.push(new Particle());
      }
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < particles.length; i++) {
        particles[i].update();
        particles[i].draw();
        for (let j = i; j < particles.length; j++) {
          const dx = particles[i].x - particles[j].x;
          const dy = particles[i].y - particles[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          if (distance < 100) {
            ctx.beginPath();
            ctx.strokeStyle = `rgba(241, 208, 209, ${1 - distance / 100})`;
            ctx.lineWidth = 1;
            ctx.moveTo(particles[i].x, particles[i].y);
            ctx.lineTo(particles[j].x, particles[j].y);
            ctx.stroke();
          }
        }
      }
      requestAnimationFrame(animate);
    }

    init();
    animate();

    window.addEventListener('resize', () => {
      canvas.width = window.innerWidth;
    });

    return () => {
      window.removeEventListener('resize', () => {
        canvas.width = window.innerWidth;
      });
    };
  }, []);

  useEffect(() => {
    const hostname = window.location.hostname;
    if (!["bearcub.io", "www.bearcub.io"].includes(hostname)) {
      window.location.href = "https://bearcub.io";
    }
  }, []);

  return (
    <div className="container">
      <header>
        <h1 data-text="Bear Cub Network">Bear Cub Network</h1>
        <p>Enhancing Connectivity for Low Latency</p>
      </header>
      <section className="projects">
        <div className="project">
          <h2>Data Center Networking</h2>
          <p>Exploring high-performance, scalable network connections with Tier 1 ISPs.</p>
        </div>
        <div className="project">
          <h2>IP Transit</h2>
          <p>Experimenting with global IP transit routes to achieve unparalleled speed and reliability.</p>
        </div>
        <div className="project">
          <h2>Peering</h2>
          <p>Developing strategic peering relationships to optimize network performance.</p>
        </div>
        <div className="project">
          <h2>BGP Sessions</h2>
          <p>Setting up and managing secure BGP sessions for efficient inter-network routing.</p>
        </div>
      </section>
      <canvas id="network-bg" className="network-bg"></canvas>
    </div>
  );
}

export default App;
